







import Vue from 'vue'

export default Vue.extend({
  props: {
    flashData: {
      type: Object,
      required: true
    }
  },
  computed: {
    email() {
      return this.flashData.email || '<>'
    }
  }
})
