import { CIcon } from '~/icons/types'

export const ciParkingP: CIcon = {
  width: '20',
  height: '20',
  paths: [
    {
      d:
        'M10.75 0h-7.5C2.562 0 2 .563 2 1.25v17.5c0 .688.563 1.25 1.25 1.25h2.5C6.438 20 7 19.437 7 18.75V15h3.75c4.133 0 7.5-3.367 7.5-7.5S14.883 0 10.75 0Zm0 10H7V5h3.75c1.375 0 2.5 1.125 2.5 2.5s-1.125 2.5-2.5 2.5Z'
    }
  ],
  ellipses: [],
  circles: [],
  name: 'parking-p',
  type: 'solid'
}
