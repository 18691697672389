

















import { USER_NS, UserState } from '~/store/modules/shared/user/state'
import { computed, defineComponent } from '@nuxtjs/composition-api'
import { useNamespacedStore } from '~/compositions/store'
import { defineComponentTranslations } from '~/utils/i18n'
import { useAnalytics } from '~/compositions/analytics'

export default defineComponent({
  i18n: defineComponentTranslations({
    out_of_credits_for_today: {
      en: 'You are out of credits for today',
      el: 'Σας τελείωσαν τα credits για σήμερα'
    },
    changes_to_existing_classifieds: {
      en:
        'If you make any changes to your existing classifieds they will not appear in the front page of Car.gr as if they were new and you cannot refresh your classifieds',
      el:
        'Αν κάνετε αλλαγές σε υπάρχουσες αγγελίες, δεν θα εμφανιστούν στην αρχική σελίδα του Car.gr σαν νέες και δεν μπορείτε να ανανεώσετε τις αγγελίες σας'
    },
    buy_more_credits_here: {
      en: 'buy more credits here',
      el: 'να αγοράσετε επιπλέον credits εδώ'
    },
    free_daily_credits: {
      en:
        'You receive {credits} free credits per day for free, but you can also',
      el:
        'Κάθε μέρα στο λογαριασμό σας προστίθενται {credits} δωρεάν credits αλλά μπορείτε επισης'
    }
  }),
  setup() {
    const { getters, state } = useNamespacedStore<UserState>(USER_NS)
    const analytics = useAnalytics()

    const creditRenewalPageUrl = computed(() => getters('creditRenewalPageUrl'))
    const freeDailyCredits = computed(
      () => state.credits && state.credits.freeDaily
    )

    function handleBuyMoreClick() {
      analytics.recordEvent({
        namespace: 'n_alert_buy_more_credits',
        action: 'click',
        label: 'Buy more credits here'
      })
    }

    return { handleBuyMoreClick, creditRenewalPageUrl, freeDailyCredits }
  }
})
