


















import { USER_NS, UserState } from '~/store/modules/shared/user/state'
import { computed, defineComponent } from '@nuxtjs/composition-api'
import { useNamespacedStore } from '~/compositions/store'
import { useAnalytics } from '~/compositions/analytics'

export default defineComponent({
  setup() {
    const { getters: userGetters } = useNamespacedStore<UserState>(USER_NS)
    const analytics = useAnalytics()

    const totalCredits = computed(() => userGetters('totalCredits'))
    const creditRenewalPageUrl = computed(() =>
      userGetters('creditRenewalPageUrl')
    )

    function handleBuyMoreButtonClick() {
      analytics.recordEvent({
        namespace: 'n_alert_low_on_credits_buy_more',
        action: 'click',
        label: 'You are low on credits buy more credits here'
      })
    }

    return { totalCredits, creditRenewalPageUrl, handleBuyMoreButtonClick }
  }
})
