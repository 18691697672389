













































import {
  defineComponent,
  computed,
  ref,
  watch,
  useRoute,
  useFetch,
  onMounted,
  onBeforeMount
} from '@nuxtjs/composition-api'
import { PAGE_NS, PageState } from '~/store/modules/shared/page/state'
import CarHeader from '~/components/car/header/CarHeader.vue'
import {
  CLASSIFIED_SEARCH_NS,
  SearchState
} from '~/store/modules/shared/classifieds/search/state'
import { PARKING_NS, ParkingState } from '~/store/modules/shared/parking/state'
import { getRouteMetaItem } from '~/utils/router'
import CarFooter from '~/components/car/footer/CarFooter.vue'
import LanguageSwitcher from '~/services/LanguageSwitcher'
import HeadService from '~/services/HeadService'
import DealerSiteService from '~/services/dealers/site/DealerSiteService'
import BackendFlashMessage from '~/components/car/BackendFlashMessage.vue'
import ClientFlashMessage from '~/components/shared/flash/ClientFlashMessage.vue'
import { FLASH_NS, FlashState } from '~/store/modules/shared/flash/state'
import FlashService from '~/services/flash/FlashService'
import AdmanAdsService from '~/services/ads/AdmanAdsService'
import CreditsAlert from '~/components/car/alert/credit/CreditsAlert.vue'
import SubscriptionExpiryAlert from '~/components/car/alert/SubscriptionExpiryAlert.vue'
import { useUserAgent } from '~/compositions/user-agent'
import { useNamespacedStore } from '~/compositions/store'
import { useDep, useDeps } from '~/compositions/dependency-container'
import {
  LOGIN_MODAL_NS,
  LoginModalState
} from '~/store/modules/shared/login-modal/state'
import AppNotifications from '~/components/shared/configurable/notification-center/AppNotifications.vue'
import AppConfirmationModal from '~/components/shared/configurable/modal/AppConfirmationModal.vue'

export default defineComponent({
  components: {
    AppNotifications,
    CarFooter,
    ParkingModalList: () =>
      import('~/pages/shared/account/parking/modal-list.vue'),
    CLoginModal: () => import('~/components/shared/login/CLoginModal.vue'),
    CreditsAlert,
    CarHeader,
    BackendFlashMessage,
    ClientFlashMessage,
    SubscriptionExpiryAlert,
    AppConfirmationModal
  },
  setup() {
    useFetch(() => {
      if (isIframe.value) {
        pageDispatch('addBodyClass', 'iframe')
      }
      if (isSubdomain.value) {
        pageDispatch('addBodyClass', 'subdomain')
      }
    })
    const route = useRoute()

    const { isMobile } = useUserAgent()

    const availableLocales = ref(useDep(LanguageSwitcher).getAvailableLocales())
    const [
      languageSwitcherService,
      admanAdsService,
      flashService,
      dealerSiteService
    ] = useDeps(
      LanguageSwitcher,
      AdmanAdsService,
      FlashService,
      DealerSiteService
    )

    const { state: loginModalState } = useNamespacedStore<LoginModalState>(
      LOGIN_MODAL_NS
    )

    const { state: classifiedSearchState } = useNamespacedStore<SearchState>(
      CLASSIFIED_SEARCH_NS
    )
    const { state: pageState, dispatch: pageDispatch } = useNamespacedStore<
      PageState
    >(PAGE_NS)

    const {
      getters: parkingGetters,
      dispatch: parkingDispatch
    } = useNamespacedStore<ParkingState>(PARKING_NS)
    const { state: flashState } = useNamespacedStore<FlashState>(FLASH_NS)

    const isLoginModalVisible = computed(
      () => loginModalState.isLoginModalVisible
    )
    const currentRoute = computed(() => classifiedSearchState.currentRoute)
    const clientFlashMessage = computed(() => flashState.clientFlashMessage)
    const flash = computed(() => pageState.flash)
    const showParking = computed(() => parkingGetters('getShowParking'))
    const isIframe = computed(() => {
      return getRouteMetaItem(route.value, 'isIframe')
    })

    const isSubdomain = computed(() => {
      return (
        getRouteMetaItem(route.value, 'isSubdomain') ||
        route.value.name?.startsWith('__subdomain')
      )
    })
    const isDealerSite = computed(() => {
      return dealerSiteService.routeIsOfDealerSite()
    })

    const isNoHeaderRoute = computed(() => {
      return getRouteMetaItem(route.value, 'noHeader')
    })
    const isNoFooterRoute = computed(() => {
      return getRouteMetaItem(route.value, 'noFooter')
    })
    const useWideContainer = computed(() => {
      return getRouteMetaItem(route.value, 'wideContainer')
    })

    const showCreditsAlert = computed(() => {
      return (
        !isDealerSite.value &&
        !(isMobile.value && inMap.value) &&
        !inTrades.value &&
        !isLanding.value
      )
    })
    const showExpiryAlert = computed(() => {
      return !isLanding.value
    })
    const showFooter = computed(() => {
      return (
        !isIframe.value &&
        !isSubdomain.value &&
        !isDealerSite.value &&
        !isNoFooterRoute.value
      )
    })
    const showHeader = computed(() => {
      return (
        !isIframe.value &&
        !isSubdomain.value &&
        !isDealerSite.value &&
        !isNoHeaderRoute.value
      )
    })
    const showTopMessages = computed(() => {
      return (
        !isIframe.value &&
        !isSubdomain.value &&
        !isDealerSite.value &&
        !inTrades.value
      )
    })
    const inMap = computed(() => {
      return getRouteMetaItem(route.value, 'inMap')
    })
    const inTrades = computed(() => {
      return getRouteMetaItem(route.value, 'inTrades')
    })
    const isLanding = computed(() => {
      return getRouteMetaItem(route.value, 'isLanding')
    })
    const inIndex = computed(() => {
      return route.value.name === '__index'
    })

    onBeforeMount(() => {
      flashService.setClientFlashFromCookie()
    })

    onMounted(() => {
      window.history.scrollRestoration = 'auto'
    })

    watch(currentRoute, val => {
      availableLocales.value = languageSwitcherService.getAvailableLocales(val)
    })
    watch(route, val => {
      availableLocales.value = languageSwitcherService.getAvailableLocales()
      if (process.client) {
        admanAdsService.refreshInnovativeFormats(val)
      }
    })

    function hideParkingModal() {
      parkingDispatch('hide_parking')
    }

    return {
      isMobile,
      currentRoute,
      flash,
      showParking,
      clientFlashMessage,
      availableLocales,
      isIframe,
      isSubdomain,
      hideParkingModal,
      useWideContainer,
      showCreditsAlert,
      showExpiryAlert,
      showFooter,
      showHeader,
      showTopMessages,
      inIndex,
      inMap,
      isLoginModalVisible
    }
  },
  head() {
    const headService = this.$dep(HeadService)
    return headService.createLayoutHead()
  }
})
