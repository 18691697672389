import { inject } from 'tsyringe'
import VueRouter, { RawLocation } from 'vue-router'
import { clientOnly } from '~/decorators'
import { containerScoped } from '~/decorators/dependency-container'
import { AvailableLocale, I18nLocale } from '~/models/shared/types'
import DealerSiteService from '~/services/dealers/site/DealerSiteService'
import LocaleService from '~/services/LocaleService'
import CookiesService from '~/services/CookiesService'
import { Store } from 'vuex'
import { RootState } from '~/store/types'
import { I18N_NS } from '~/store/modules/shared/i18n/state'
import LoggerService from '~/services/LoggerService'
import { carPublicLocales, plotPublicLocales } from '~/constants/i18n'
import { APP_NS } from '~/store/modules/shared/app/state'

@containerScoped()
export default class LanguageSwitcher {
  constructor(
    @inject(CookiesService) private cookies: CookiesService,
    @inject(VueRouter) private router: VueRouter,
    @inject(LoggerService) private logger: LoggerService,
    @inject(Store) private store: Store<RootState>,
    @inject(LocaleService)
    private localeService: LocaleService,
    @inject(DealerSiteService) private dealerSiteService: DealerSiteService
  ) {}

  @clientOnly
  async handleLanguageChange(e: Event, aloc: AvailableLocale) {
    e.preventDefault()

    if (this.store.getters[`${I18N_NS}/localeIsActive`](aloc.locale)) {
      return
    }
    try {
      await this.switchTo(aloc)
    } catch (error) {
      this.logger.captureError(error)
    }
  }

  @clientOnly
  switchTo(locale: AvailableLocale) {
    if (this.dealerSiteService.routeIsOfDealerSite()) {
      this.cookies.set('lang', locale.locale)
      window.location.href = window.location.href.toString()
      return
    }

    window.location.href = locale.link
  }

  getCurrentUrlWithLocale({
    locale,
    customRoute = ''
  }: {
    locale: I18nLocale
    customRoute?: any
  }): string {
    const route = customRoute || this.router.currentRoute

    return this.router.resolve({
      ...route,
      query: { ...route.query, lang: locale }
    } as RawLocation).href
  }

  getAvailableLocales(customRoute = null): AvailableLocale[] {
    const appIsCar = this.store.getters[`${APP_NS}/isCar`]
    const publicLocales = appIsCar ? carPublicLocales : plotPublicLocales

    return publicLocales.map(locale => {
      return {
        locale,
        title: this.localeService.getLocaleLabel(locale),
        icon: this.localeService.getLocaleIcon(locale),
        link: this.getCurrentUrlWithLocale({
          locale,
          customRoute
        })
      }
    })
  }
}
