import { CIcon } from '~/icons/types'
export const ciUserCircle: CIcon = {
  width: 25,
  height: 24,
  paths: [
    {
      d:
        'M24.5,12c0-6.6-5.4-12-12-12s-12,5.4-12,12c0,6.6,5.4,12,12,12c2.3,0,4.5-0.7,6.3-1.8c0.2-0.1,0.5-0.3,0.7-0.5   C22.5,19.6,24.5,16,24.5,12z M1.5,12c0-6.1,4.9-11,11-11s11,4.9,11,11c0,3-1.2,5.8-3.2,7.8c-0.2-2.4-2.2-4.4-4.7-4.4H15   c-0.8,0.4-1.6,0.6-2.5,0.6s-1.8-0.2-2.5-0.6H9.4c-2.5,0-4.5,1.9-4.7,4.4C2.7,17.7,1.5,15,1.5,12z M12.5,23c-2.6,0-5-0.9-6.8-2.4   v-0.5c0-2,1.7-3.7,3.7-3.7h0.4c0.8,0.4,1.8,0.6,2.8,0.6c1,0,1.9-0.2,2.8-0.6h0.4c2,0,3.7,1.7,3.7,3.7v0.6   C17.4,22.1,15.1,23,12.5,23z'
    }
  ],
  ellipses: [],
  circles: [{ cx: '12.5', cy: '9.8', r: '4.5' }],
  name: 'user-circle',
  type: 'regular'
}
