








import AppFooter from '~/components/shared/footer/AppFooter.vue'
import {
  faApple,
  faFacebookSquare,
  faInstagram,
  faYoutube
} from '@fortawesome/free-brands-svg-icons'
import { mapGetters } from 'vuex'
import { USER_NS } from '~/store/modules/shared/user/state'
import { mapDeps } from '~/plugins/dependency-container/utils'
import { LegacyUrlService } from '~/services/legacy/url/LegacyUrlService'
import UserAgentService from '~/services/UserAgentService'
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  components: { AppFooter },
  props: {
    availableLocales: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters(USER_NS, {
      isAnon: 'isAnon'
    }),
    ...mapDeps({
      userAgentService: UserAgentService
    }),
    ...mapDeps({ legacyUrlService: LegacyUrlService }),
    icons() {
      return {
        facebook: faFacebookSquare,
        instagram: faInstagram,
        youtube: faYoutube,
        apple: faApple
      }
    },
    footerMenu() {
      return [
        {
          column_menus: [
            {
              menu_title: 'Car',
              mobile_collapsible: true,
              links: [
                {
                  title: this.$t('support'),
                  url: '/information/support/'
                },
                {
                  title: this.$t('market guide'),
                  url: '/information/simboulos/'
                },
                {
                  title: 'Blog - Car.gr',
                  url: 'https://blog.car.gr/'
                },
                {
                  title: this.$t('deals'),
                  url: '/deals/'
                },
                {
                  title: this.$t('job offers'),
                  url: '/information/careers/'
                }
              ]
            }
          ]
        },
        {
          column_menus: [
            {
              menu_title: this.$t('dealers'),
              mobile_collapsible: true,
              links: [
                {
                  title: this.$t('register'),
                  url: '/dealers/register/',
                  hide: !this.isAnon
                },
                {
                  title: this.$t('login'),
                  url: '/login/',
                  hide: !this.isAnon
                },
                {
                  title: this.$t('frequently asked questions'),
                  url: '/information/dealers_faq/'
                },
                {
                  title: this.$t('advertise in car.gr'),
                  url: '/advertising'
                }
              ]
            }
          ]
        },
        {
          column_menus: [
            {
              menu_title: this.$t('individuals'),
              mobile_collapsible: true,
              links: [
                {
                  title: this.$t('register'),
                  url: this.legacyUrlService.getRegisterUrl(),
                  hide: !this.isAnon
                },
                {
                  title: this.$t('login'),
                  url: this.legacyUrlService.getUserLoginUrl(),
                  hide: !this.isAnon
                },
                {
                  title: this.$t('frequently asked questions'),
                  url: '/information/users_faq/'
                }
              ]
            }
          ]
        },
        {
          extra_classes: 'socials-and-apps',
          column_menus: [
            {
              menu_title: 'Follow us',
              mobile_collapsible: false,
              links: [
                {
                  title: 'Facebook',
                  hide_title: true,
                  url: 'https://www.facebook.com/Car.grOfficial/',
                  icon: { type: 'icon', icon: this.icons.facebook },
                  target: '_blank',
                  rel: 'noreferrer'
                },
                {
                  title: 'Instagram',
                  hide_title: true,
                  url: 'https://www.instagram.com/car.grofficial/',
                  icon: { type: 'icon', icon: this.icons.instagram },
                  target: '_blank',
                  rel: 'noreferrer'
                },
                {
                  title: 'YouTube',
                  hide_title: true,
                  url: 'https://www.youtube.com/c/cargr?sub_confirmation=1',
                  icon: { type: 'icon', icon: this.icons.youtube },
                  target: '_blank',
                  rel: 'noreferrer'
                }
              ]
            },
            {
              menu_title: 'Mobile apps',
              mobile_collapsible: false,
              links_class: 'apps',
              links: [
                {
                  title: 'Android',
                  hide_title: true,
                  url:
                    'https://play.google.com/store/apps/details?id=com.car.gr',
                  target: '_blank',
                  icon: {
                    type: 'img',
                    class: 'playstore',
                    src: require('~/assets/img/brands/google-play-store.svg'),
                    width: 27,
                    height: 27
                  },
                  extra_classes: !this.userAgentService.isAndroidOs()
                    ? 'max-sm:!tw-hidden'
                    : null,
                  rel: 'noreferrer'
                },
                {
                  title: 'iOS',
                  hide_title: true,
                  url:
                    'https://itunes.apple.com/gr/app/car-gr/id1278899131?mt=8',
                  target: '_blank',
                  icon: {
                    type: 'img',
                    src: require('~/assets/img/brands/apple-ios-store.svg'),
                    width: 27,
                    height: 27
                  },
                  extra_classes: !this.userAgentService.isIos()
                    ? 'max-sm:!tw-hidden'
                    : null,
                  rel: 'noreferrer'
                },
                {
                  title: 'Huawei AppGallery',
                  hide_title: true,
                  url: 'https://appgallery7.huawei.com/#/app/C101510159',
                  icon: {
                    type: 'img',
                    class: 'huawei',
                    src: require('~/assets/img/brands/huawei-app-gallery.svg'),
                    width: 27,
                    height: 27
                  },
                  extra_classes: !this.userAgentService.isAndroidOs()
                    ? 'max-sm:!tw-hidden'
                    : null,
                  rel: 'noreferrer'
                }
              ]
            }
          ]
        }
      ]
    }
  }
})
