import { CIcon } from '~/icons/types'
export const ciUserCircle: CIcon = {
  width: 25,
  height: 24,
  paths: [
    {
      d:
        'M12.5 0C5.9 0 .5 5.4.5 12s5.4 12 12 12 12-5.4 12-12-5.4-12-12-12zm7.8 19.8c-.2-2.4-2.2-4.4-4.7-4.4H15c-.8.4-1.6.6-2.5.6-.9 0-1.8-.2-2.5-.6h-.6c-2.5 0-4.5 1.9-4.7 4.4-2-2-3.2-4.7-3.2-7.7 0-6.1 4.9-11 11-11s11 4.9 11 11c0 2.9-1.2 5.7-3.2 7.7z'
    }
  ],
  circles: [{ cx: '12.5', cy: '9.8', r: '4.5', fill: 'currentColor' }],
  name: 'user-circle',
  type: 'solid'
}
