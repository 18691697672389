














































import { USER_NS, UserState } from '~/store/modules/shared/user/state'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { format } from '~/utils/date'
import { ONE_DAY } from '~/constants/duration'
import { computed, defineComponent } from '@nuxtjs/composition-api'
import { useNamespacedStore } from '~/compositions/store'
import { useCookies } from '~/compositions/useCookies'

export const SUBSCRIPTION_EXPIRE_WARNING_COOKIE_NAME = 'hideExpireWarning'

export default defineComponent({
  setup() {
    const { state: userState } = useNamespacedStore<UserState>(USER_NS)
    const cookies = useCookies()

    const subscription = computed(() => userState.subscription)

    const expiresSoon = computed(() => {
      const expiresInDays = subscription.value?.expiresInDays

      return expiresInDays ||
        (expiresInDays === 0 && !subscription.value?.expired)
        ? expiresInDays < 10
        : false
    })

    const show = computed(() => {
      if (cookies.get(SUBSCRIPTION_EXPIRE_WARNING_COOKIE_NAME)) {
        return false
      }

      return subscription.value?.expired || expiresSoon.value
    })

    const formattedExpirationDate = computed(() => {
      if (!subscription.value) {
        return ''
      }

      return format(new Date(subscription.value.expires))
    })

    function handleAlertDismiss() {
      cookies.set(SUBSCRIPTION_EXPIRE_WARNING_COOKIE_NAME, 1, {
        maxAge: ONE_DAY
      })
    }

    return {
      formattedExpirationDate,
      show,
      expiresSoon,
      subscription,
      faExclamationCircle,
      handleAlertDismiss
    }
  }
})
